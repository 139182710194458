import React from "react"
import styled from "styled-components"

const ContentBackground = styled.div`
  background: linear-gradient(#0000001d, #00000000), var(--cool-black);
  background-position: 50% 50%;
  background-attachment: fixed;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4%;
  padding-bottom: 4%;

  @media (max-width: 1023px) {
    padding-top: 90px;
  }
`
const Content = styled.div`
  max-width: 1280px;
  margin-top: 5%;
  h1 {
    font-family: "montserrat" !important;
    font-size: 2.5em;
    font-weight: 800;
    color: var(--white);
    text-align: center;

    @media (max-width: 1023px) {
      margin: 0 5%;
    }
  }
  h4 {
    font-family: "Open Sans";
    font-size: 1.2em;
    font-weight: 200;
    text-align: center;
    color: var(--white);
    padding: 20px 15%;
  }
`

export default function ContentSection1() {
  return (
    <ContentBackground>
      <Content>
        <h1>Terms of Service</h1>
      </Content>
    </ContentBackground>
  )
}
